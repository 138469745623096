import { Injectable } from '@angular/core';
import Smartlook from 'smartlook-client';
import {environment} from "../../environments/environment";

declare var gtag;
declare var ym;

export enum TrackerEvent {
  signin = 'signin',
  signup = 'signup',
  newPostClick = 'newPostClick',
  becomeACreatorPress = 'becomeACreatorPress',
  becomeACreatorSkipAgency = 'becomeACreatorSkipAgency',
  becomeACreatorPressAgency = 'becomeACreatorPressAgency',
  becomeACreatorBioSubmit = 'becomeACreatorBioSubmit',
  becomeACreatorCountrySubmit = 'becomeACreatorCountrySubmit',
  becomeACreatorKycStart = 'becomeACreatorKycStart',
  buyPostClick = 'buyPostClick',
  buyMessageClick = 'buyMessageClick',
  donateFromProfile = 'donateFromProfile',
  donateFromPost = 'donateFromPost',
  donateFromMessage = 'donateFromMessage',
  sendMessage = 'sendMessage',
  goToCreatorProfile = 'goToCreatorProfile',
  followFreeClick = 'followFreeClick',
  followPayedClick = 'followPayedClick',
  buySubscription = 'buySubscription',
  unfollow = 'unfollow',
  rejectedTransaction = 'rejectedTransaction',
  finishedTransaction = 'finishedTransaction'
}

@Injectable({
  providedIn: 'root'
})
export class EventsTrackerService {
  private events = TrackerEvent;
  constructor() { }

  public signin(email: string, returnUrl?: string) {
    let data = {email: email, returnUrl: returnUrl};
    this._trackEvent(this.events.signin, JSON.stringify(data));
  }

  public signup(email: string, returnUrl?: string) {
    let data = {email: email, returnUrl: returnUrl};
    this._trackEvent(this.events.signup, JSON.stringify(data));
  }

  public newPost(isLoggedIn: boolean, isCreator: boolean, userId?: string) {
    let data = {isLoggedIn: isLoggedIn, isCreator: isCreator, userId: userId};
    this._trackEvent(this.events.newPostClick, JSON.stringify(data));
  }

  public becomeACreatorPress(isLoggedIn: boolean, userId?: string) {
    let data = {isLoggedIn: isLoggedIn, userId: userId};
    this._trackEvent(this.events.becomeACreatorPress, JSON.stringify(data));
  }

  public becomeACreatorSkipAgency(userId: string) {
    let data = {userId: userId};
    this._trackEvent(this.events.becomeACreatorSkipAgency, JSON.stringify(data));
  }

  public becomeACreatorPressAgency(userId: string) {
    let data = {userId: userId};
    this._trackEvent(this.events.becomeACreatorPressAgency, JSON.stringify(data));
  }

  public becomeACreatorBioSubmit(userId: string) {
    let data = {userId: userId};
    this._trackEvent(this.events.becomeACreatorBioSubmit, JSON.stringify(data));
  }

  public becomeACreatorCountrySubmit(userId: string) {
    let data = {userId: userId};
    this._trackEvent(this.events.becomeACreatorCountrySubmit, JSON.stringify(data));
  }

  public becomeACreatorKycStart(userId: string) {
    let data = {userId: userId};
    this._trackEvent(this.events.becomeACreatorKycStart, JSON.stringify(data));
  }

  public buyPostClick(isLoggedIn: boolean, postId: string, userId?: string,) {
    let data = {isLoggedIn: isLoggedIn, postId: postId, userId: userId};
    this._trackEvent(this.events.buyPostClick, JSON.stringify(data));
  }

  public buyMessageClick(userId: string, messageId: string, creatorId: string) {
    let data = {userId: userId, messageId: messageId, creatorId: creatorId};
    this._trackEvent(this.events.buyMessageClick, JSON.stringify(data));
  }

  public sendMessage(userId: string, chatId: string) {
    let data = {userId: userId, chatId: chatId};
    this._trackEvent(this.events.sendMessage, JSON.stringify(data));
  }

  public donateFromProfile(isLoggedIn: boolean, creatorId: string, userId?: string) {
    let data = {isLoggedIn: isLoggedIn, creatorId: creatorId, userId: userId};
    this._trackEvent(this.events.donateFromProfile, JSON.stringify(data));
  }

  public donateFromPost(isLoggedIn: boolean, creatorId: string, userId?: string) {
    let data = {isLoggedIn: isLoggedIn, creatorId: creatorId, userId: userId};
    this._trackEvent(this.events.donateFromPost, JSON.stringify(data));
  }

  public donateFromMessage(targetId: string, userId: string) {
    let data = {targetId: targetId, userId: userId};
    this._trackEvent(this.events.donateFromMessage, JSON.stringify(data));
  }

  public goToCreatorProfile(isLoggedIn: string, targetId: string, userId?: string) {
    let data = {targetId: targetId, userId: userId};
    this._trackEvent(this.events.goToCreatorProfile, JSON.stringify(data));
  }

  public buyPostSuccess(targetId: string, userId: string) {
    let data = {postId: targetId, userId: userId};
    this._trackEvent(TrackerEvent.finishedTransaction, JSON.stringify(data));
  }

  public buyPostError(targetId: string, userId: string) {
    let data = {postId: targetId, userId: userId};
    this._trackEvent(TrackerEvent.rejectedTransaction, JSON.stringify(data));
  }

  public buyMessageSuccess(targetId: string, userId: string) {
    let data = {messageId: targetId, userId: userId};
    this._trackEvent(TrackerEvent.finishedTransaction, JSON.stringify(data));
  }

  public buyMessageError(targetId: string, userId: string) {
    let data = {messageId: targetId, userId: userId};
    this._trackEvent(TrackerEvent.rejectedTransaction, JSON.stringify(data));
  }

  public donateSuccess(targetId: string, userId: string) {
    let data = {toUserId: targetId, fromUserId: userId};
    this._trackEvent(TrackerEvent.finishedTransaction, JSON.stringify(data));
  }

  public donateError(targetId: string, userId: string) {
    let data = {toUserId: targetId, fromUserId: userId};
    this._trackEvent(TrackerEvent.rejectedTransaction, JSON.stringify(data));
  }

  public followButtonClick(action: TrackerEvent, userId: string, targetId: string) {
    let data = {action: action.toString(), userId: userId, targetId: targetId};
    this._trackEvent(action, JSON.stringify(data));
  }

  private _trackEvent(event: TrackerEvent, data: string, category?: string) {
    this._trackGoogleEvent(event.toString(), data, category);
    this._trackSmartLookEvent(event.toString(), data, category);
    this._trackYMEvent(event.toString(), data);
  }

  private _trackGoogleEvent(eventName: string, eventDetails: string = '', eventCategory: string = '') {
    if (!environment.isProd) {
      return;
    }
    gtag('event', eventName, {
      'event_category': eventCategory,
      'event_label': eventName,
      'value': eventDetails
    });
  }
  private _trackSmartLookEvent(eventName: string, eventDetails: string = '', eventCategory: string = '') {
    if (!environment.isProd) {
      return;
    }
    Smartlook.track(eventName, {
      details: eventDetails,
      category: eventCategory,
    });
  }
  private _trackYMEvent(eventName: string, eventDetails: string = '') {
    if (!environment.isProd) {
      return;
    }
    ym(98127287, eventName, eventDetails); return true;
  }
}
